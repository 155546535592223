<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchForm">
            <!-- <el-form-item label="公司名称">
              <el-input v-model="formInline.user" placeholder="公司名称"></el-input>
            </el-form-item>-->
            <el-form-item label="设备类型" prop="type">
              <el-select v-model="formInline.type" placeholder="设备类型" disabled>
                <el-option
                  v-for="item in deviceType"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="imei" prop="imei">
              <el-input v-model="formInline.imei" placeholder="请输入IMEI"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchList">查询</el-button>
              <el-button type="primary" @click="resetList">重置</el-button>
              <!--              <el-button type="primary" @click="onSubmit">导出</el-button>-->
            </el-form-item>
          </el-form>
        </div>
      </template>

      <el-row :gutter="20">
        <el-col>
          <div class="grid-content bg-purple">
            <el-button type="primary" @click="issueCommand">指令下发</el-button>
            <h3 class="tableTip">下发指令日志</h3>
            <el-table
              :data="commandData"
              stripe
              border
              style="width: 100%"
              :default-sort="{prop: 'date', order: 'descending'}"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="content" label="指令内容"></el-table-column>
              <el-table-column prop="type" label="指令类型"></el-table-column>
              <el-table-column prop="device_type" label="设备类型" width="80"></el-table-column>
              <el-table-column prop="imei" label="IMEI" width="160"></el-table-column>
              <el-table-column prop="status" label="下发状态" width="80"></el-table-column>
              <el-table-column prop="name" label="操作员" width="100"></el-table-column>
              <el-table-column prop="create_at" label="下发时间"></el-table-column>
              <el-table-column prop="update_at" label="更新时间"></el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 50]"
              :page-size="formInline.size"
              fixed="right"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal"
            ></el-pagination>

            <el-dialog title="下发指令" v-model="directDialog" center top="10vh">
              <el-form :model="infoForm" label-width="100px">
                <el-form-item label="设备类型">
                  <el-select v-model="infoForm.device_type" placeholder="设备类型" disabled>
                    <el-option
                      v-for="item in deviceType"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="指令类型" prop="name">
                  <el-select v-model="infoForm.type" placeholder="选择类型">
                    <el-option label="重启" :value="1"></el-option>
                    <el-option label="即时定位" :value="2"></el-option>
                    <el-option label="关机" :value="3"></el-option>
                    <el-option label="测量心率" :value="4"></el-option>
                    <el-option label="GPS定位间隔时间" :value="5"></el-option>
                    <el-option label="心率上传间隔" :value="6"></el-option>
                    <el-option label="体温实时测量" :value="7"></el-option>
                    <el-option label="体温测量频率" :value="8"></el-option>
                    <el-option label="计步器开关" :value="9"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="GPS开关">
                  <el-radio-group v-model="infoForm.gps">
                    <el-radio label="开"></el-radio>
                    <el-radio label="关"></el-radio>
                    <el-radio label="全开"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="选择设备" label-width="100px">
                  <el-input
                    v-model="infoForm.imei_list"
                    readonly
                    style="width:300px;margin-right:10px;"
                  ></el-input>
                  <el-button type="primary" @click="addCommand">添加</el-button>
                  <el-button type="primary" @click="delCommand">删除</el-button>
                </el-form-item>
                <el-form-item label="选择周期">
                  <el-checkbox-group v-model="infoForm.week" size="large">
                    <el-checkbox-button v-for="day in weeks" :key="day" :label="day">{{ day }}</el-checkbox-button>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="选择时间">
                  <el-time-picker
                    v-model="infoForm.time"
                    placeholder="选择下发时间"
                  ></el-time-picker>
                </el-form-item>
                <el-form-item
                  prop="is_open"
                  v-if="[5,6,9].indexOf(infoForm.type)>-1"
                  label-width="100px"
                >
                  <el-switch v-model="infoForm.is_open" active-text="开" inactive-text="关"></el-switch>
                </el-form-item>
                <el-form-item
                  v-if="[5,6].indexOf(infoForm.type)>-1"
                  label="指令内容"
                  prop="content"
                  label-width="100px"
                >
                  <el-input
                    :placeholder="infoForm.type==8?'数值范围：1-12(小时)':'数值范围：300-65535(秒)'"
                    v-model="infoForm.content"
                  ></el-input>
                </el-form-item>
              </el-form>
              <template #footer>
                <span class="dialog-footer">
                  <el-button @click="commandOff">取 消</el-button>
                  <el-button type="primary" @click="commandSend">确 定</el-button>
                </span>
              </template>
            </el-dialog>

            <el-dialog :title="dialogTit" v-model="addDialog" width="70%" center>
              <div style="padding-bottom:60px;">
                <el-form :model="addform" :inline="true" ref="searchForms">
                  <el-form-item label="IMEI" prop="imei_keyword">
                    <el-input v-model="addform.imei_keyword" placeholder="请输入IMEI"></el-input>
                  </el-form-item>
                  <el-form-item label="设备状态" prop="online">
                    <el-select v-model="addform.online" placeholder="请选择设备状态域">
                      <el-option label="在线" value="1"></el-option>
                      <el-option label="离线" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="归属客户" prop="client_id">
                    <el-cascader
                        :options="clientData"
                        placeholder="选择归属客户"
                        v-model="addform.client_id"
                    ></el-cascader>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="addSearch">查询</el-button>
                    <el-button @click="resetSearch">重置</el-button>
                    <el-button @click="handleAddDevice" type="primary">保存</el-button>
                  </el-form-item>
                </el-form>
                <el-table
                  :data="deviceData"
                  @select="handleSelect"
                  @select-all="handleSelectAll"
                  ref="deviceTable"
                >
                  <el-table-column type="selection" width="55"></el-table-column>
                  <el-table-column label="设备IMEI" property="imei" width="150"></el-table-column>
                  <el-table-column label="设备类型" property="type"></el-table-column>
                  <el-table-column label="设备状态" property="online"></el-table-column>
                  <el-table-column label="设备名称" property="name"></el-table-column>
                  <el-table-column label="创建时间" property="create_at"></el-table-column>
                </el-table>
                <el-pagination
                  @size-change="handleSizeChangeadd"
                  @current-change="handleCurrentChangeadd"
                  :current-page="addform.page"
                  :page-size="addform.size"
                  layout="total, prev, pager, next, jumper"
                  :total="addCount"
                ></el-pagination>
              </div>
            </el-dialog>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import control from "@/model/control";
import device from "@/model/device";
import client from "@/model/client";

export default {
  name: "groupDirect",
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      formInline: {
        imei: "",
        type: 1,
        page: 1,
        size: 10
      },
      deviceType: [],
      commandData: [],
      clientData: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      directDialog: false,
      addDialog: false,
      deviceData: [],
      infoForm: {
        imei_list: "",
        device_type: 1,
        type: 1,
        is_open: true,
        content: "",
        week: []
      },
      dialogTit: "添加设备",
      addCount: "",
      addform: {
        imei: "",
        imei_keyword: "",
        type: 1,
        online: "",
        client_id: "",
        page: 1,
        size: 8
      },
      selectDevice: [],
      weeks: ["每天", "周一", "周二", "周三", "周四", "周五", "周六", "周日"]
    };
  },
  async mounted() {
    await this.clientList();
    await this.commandList();
    await this.deviceTypeArr();
  },
  methods: {
    async deviceTypeArr() {
      const ret = await device.config();
      if (ret.code === 200) {
        this.deviceType = ret.data;
      }
    },
    async clientList() {
      const ret = await client.list();
      if (ret.code === 200) {
        this.clientData = this.handleClientTree(ret.data)
      }
    },
    handleClientTree(data) {
      if (data.length === 0) {
        return []
      }
      let options = []
      data.forEach(item => {
        options.push({
          value: item.id,
          label: item.name
        })
        if (item.children.length > 0) {
          options.push({
            value: item.id,
            label: item.name,
            children: this.handleClientTree(item.children)
          })
        }
      })
      return options
    },
    async commandList() {
      const ret = await control.command(this.formInline);
      if (ret.code === 200) {
        this.commandData = ret.data.list;
        this.pageTotal = ret.data.count;
      }
    },
    async commandIssue() {
      const ret = await control.issueCommand(this.infoForm);
      if (ret.code === 200) {
        this.$message.success("下发指令成功");
        this.directDialog = false;
        this.commandList();
      } else {
        this.$message.error(ret.message);
      }
    },
    async deviceList() {
      const ret = await device.group(this.addform);
      if (ret.code === 200) {
        this.deviceData = ret.data.list;
        this.addCount = ret.data.count;
        setTimeout(() => {
          this.deviceData.forEach(v => {
            this.$refs.deviceTable.toggleRowSelection(
              v,
              this.selectDevice.indexOf(v.imei) > -1
            );
          });
        });
      }
    },
    handleSelect(selection, row) {
      const index = this.selectDevice.indexOf(row.imei);
      if (index > -1) {
        this.selectDevice.splice(index, 1);
      } else {
        this.selectDevice.push(row.imei);
      }
    },
    handleSelectAll(selection) {
      if (selection.length === 0) {
        this.deviceData.forEach(v => {
          let index = this.selectDevice.indexOf(v.imei);
          if (index > -1) {
            this.selectDevice.splice(index, 1);
          }
        });
      } else {
        this.deviceData.forEach(v => {
          let index = this.selectDevice.indexOf(v.imei);
          if (index < 0) {
            this.selectDevice.push(v.imei);
          }
        });
      }
    },
    searchList() {
      this.commandList();
    },
    resetList() {
      this.$refs.searchForm.resetFields();
      this.commandList();
    },
    issueCommand() {
      this.directDialog = true;
      this.infoForm.type = "";
      this.infoForm.imei_list = [];
    },
    addCommand() {
      this.dialogTit = "添加设备";
      this.addDialog = true;
      // this.infoForm.imei_list = [];
      this.addform.imei = [];
      this.addform.imei_keyword = "";
      this.addform.online = "";
      this.selectDevice = JSON.parse(JSON.stringify(this.infoForm.imei_list));
      this.deviceList();
    },
    handleAddDevice() {
      console.log(this.selectDevice);
      this.infoForm.imei_list = JSON.parse(JSON.stringify(this.selectDevice));
      this.addDialog = false;
    },
    delCommand() {
      this.addDialog = true;
      this.dialogTit = "删除已选设备";
      this.selectDevice = JSON.parse(JSON.stringify(this.infoForm.imei_list));
      this.addform.imei_keyword = "";
      this.addform.imei =
        this.selectDevice.length == 0 ? [""] : this.selectDevice.join(",");
      this.deviceList();
    },
    commandOff() {
      this.directDialog = false;
    },
    commandSend() {
      this.infoForm.imei_list = this.infoForm.imei_list.toString();
      this.commandIssue();
    },
    addSearch() {
      this.deviceList();
    },
    resetSearch() {
      this.$refs.searchForms.resetFields();
      this.deviceList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.commandList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.commandList();
    },
    handleSizeChangeadd(val) {
      this.addform.size = val;
      this.deviceList();
    },
    handleCurrentChangeadd(val) {
      this.addform.page = val;
      this.deviceList();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}
.card-header {
  display: flex;
  align-items: center;
}
.el-table {
  margin-top: 20px;
}
.el-pagination {
  margin: 30px 0px;
  float: right;
}
.tableTip {
  color: hotpink;
  border-bottom: 3px solid hotpink;
  line-height: 50px;
  width: 100px;
  text-align: center;
  margin-top: 20px;
}
</style>


