import request from '../utils/request'

export default {
  async command (data) {      // 获取指令下发列表
    const ret = await request('/v1/group/command', data,'POST')
    return ret
  },
  async issueCommand (data) {   //下发指令
    const ret = await request('/v1/group/issue_command', data,'POST')
    return ret
  },
  async alarm (data) {        // 获取报警设置列表
    const ret = await request('/v1/group/alarm', data,'POST')
    return ret
  },
  async alarmSet (data) {     // 上传报警设置接口
    const ret = await request('/v1/group/alarm_setting', data,'POST')
    return ret
  },
  async delete(data){
    const ret = await request('/v1/group/delete_alarm_setting', data,'POST')
    return ret
  }
}
